<template>
  <div class="body">
    <section class="banner">
      <div class="summary">
        <img class="brand" src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/brand.png" alt="橙仕01特别版" width="463" height="119" />
        <p class="tags">
          <span>车身小</span>
          <span>空间大</span>
          <span>安全合规</span>
          <span>效率分拣</span>
        </p>
        <img class="slogan" src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/slogan1.png" alt="为社区电商而生" width="409" height="53" />
        <span class="btnBook" @click="showPopup">申请抢购资格</span>
      </div>

      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/banner.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/banner.png"
          width="1270" height="1080"
        />
      </picture>
    </section>

    <section class="box pass">
      <h3><strong>车身小巧,</strong>社区小道轻松通行用无压力</h3>
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/carpass.jpg"
          media="all and (max-width: 500px)"
        />
        <img class="ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/carpass.jpg"
          width="1255" height="690"
        />
      </picture>
      <p>车头窄、轴距短、转弯半径小，<br class="mShow" />复杂狭小空间照样能通行</p>
    </section>

    <section class="box space">
      <h3><strong>小车</strong>也有<strong>大空间</strong></h3>
      <p>4.6立方米的超大空间<span class="mHide">，</span><br class="mShow" />胜过社区果蔬店</p>
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/carspace.png"
          media="all and (max-width: 500px)"
        />
        <img class="ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/carspace.png"
          width="895" height="640"
        />
      </picture>
    </section>

    <section class="box cases">
      <h3><strong>专为社区打造</strong><span class="mHide">的</span><br class="mShow" />分区解决方案</h3>
      <p>多种商品合理分配空间进行摆放</p>
      <ul class="list">
        <li>
          <picture>
            <source
              srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/partition.jpg"
              media="all and (max-width: 500px)"
            />
            <img class="ani"
              src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/partition.jpg"
              width="600" height="415"
            />
          </picture>
          <h4>上下两层，不同分区</h4>
          <p>合理分配每一个空间</p>
        </li>
        <li>
          <picture>
            <source
              srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/basket.jpg"
              media="all and (max-width: 500px)"
            />
            <img class="ani"
              src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/basket.jpg"
              width="600" height="415"
            />
          </picture>
          <h4>推拉式筐篮</h4>
          <p>方便耐用更实用</p>
        </li>
      </ul>
    </section>
    
    <section class="box shelf">
      <h3><strong>轻轻一掀</strong> 货物即来</h3>
      <p>简单易用的箱体构造，大大节省派送时长</p>
      <p class="tags">
        <span>防雨</span>
        <span>便捷</span>
        <span>耐用</span>
      </p>
      <div class="view ani">
        <picture>
          <source
            srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/carshelf.png"
            media="all and (max-width: 500px)"
          />
          <img
            src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/carshelf.png"
            width="885" height="600"
          />
        </picture>
        <picture>
          <source
            srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/carshelfdetail.png"
            media="all and (max-width: 500px)"
          />
          <img class="detail"
            src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/carshelfdetail.png"
            width="385" height="342"
          />
        </picture>
      </div>
    </section>

    <section class="box safe">
      <div class="summary">
        <h3>多种测试<span class="mHide">, </span><strong>保障安全</strong></h3>
        <p>箱体结构经过专业化的行驶测试<br class="mShow" />和碰撞等一系列测试<br />让整车更加坚固耐用</p>
      </div>
      <picture class="imgWrap">
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/carsafe.png"
          media="all and (max-width: 500px)"
        />
        <img class="ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/carsafe.png"
          width="1040" height="510"
        />
      </picture>
    </section>

    <section class="box delivery">
      <div class="wrap">
        <div class="summary ani">
          <h3><strong>配送效率提升</strong></h3>
          <p>融合社区配送多订单和商品特性<span class="mHide">，</span><br />通过车身的重构设计<span class="mHide">，</span><br class="mShow" />提升社区团购的配送效率</p>
        </div>
        <picture>
          <source
            srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/delivery.jpg"
            media="all and (max-width: 500px)"
          />
          <img
            src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/delivery.jpg"
            width="1309" height="874"
          />
        </picture>
      </div>
    </section>

    <section class="box scenes">
      <h3>应用场景</h3>
      <p class="tags">
        <span>社区团购</span>
        <span>店铺进货</span>
        <span>快递配送</span>
        <span>橙仕拉货</span>
      </p>
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ec/m/carscenes.png"
          media="all and (max-width: 500px)"
        />
        <img class="view ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/carscenes.png"
          width="1119" height="652"
        />
      </picture>

      <!-- <div class="view ani">
        <img class="courier" src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/courier.png" width="242" height="504" />
        <img class="car" src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/carscenes_car.png" width="1082" height="652" />
      </div> -->
    </section>

    <section class="bottom">
      <img class="brand" src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/brand.png" alt="橙仕01特别版" width="463" height="119" />
      <img class="slogan" src="https://alihtml.juzhen.cn/juzhen/assets/img/ec/slogan2.png" alt="橙仕新型配送解决方案" width="391" height="45" />
      <span class="btnBook" @click="showPopup">申请抢购资格</span>
    </section>

    <transition name="modal">
      <div class="popup" v-if="popupShow">
        <div class="popupMasker"></div>
        <div class="popupContent">
          <div class="bookView" v-show="!submited">
            <span class="close" @click="hidePopup"></span>
            <div class="tips">
              <h3 class="title">订购说明</h3>
              <p>请如实填写申请资料，我们的销售人员会及时和你联系订购信息</p>
            </div>

            <form @submit="submitPopup">
              <ul class="formList">
                <li class="item">
                  <label for="name">姓名</label>
                  <div class="input">
                    <input
                      id="name"
                      v-model.trim="formData.name"
                      name="name"
                      type="text"
                      maxlength="20"
                      placeholder="请输入你的姓名"
                    />
                    <p class="error" v-show="formDataCheck.name">请输入你的姓名</p>
                  </div>
                </li>
                <li class="item">
                  <label for="mobile">手机号</label>
                  <div class="input">
                    <input
                      id="mobile"
                      v-model.trim="formData.mobile"
                      name="mobile"
                      type="text"
                      maxlength="20"
                      placeholder="请输入你的手机号"
                    />
                    <p class="error" v-show="formDataCheck.mobile">请输入你的手机号</p>
                  </div>
                </li>
                <li class="item" style="border-bottom: none">
                  <label for="area">所在城市</label>
                  <div class="input">
                    <input
                      id="area"
                      v-model.trim="formData.area"
                      name="area"
                      type="text"
                      maxlength="20"
                      placeholder="请输入所在城市"
                    />
                    <p class="error" v-show="formDataCheck.area">请输入所在城市</p>
                  </div>
                </li>
              </ul>
              <ul class="formList">
                <li class="item itemShow">
                  <label for="remark">备注</label>
                  <textarea
                    id="remark"
                    v-model.trim="formData.remark"
                    name="remark"
                    placeholder="请输入需要备注的信息"
                  ></textarea>
                </li>
              </ul>
              <div class="formBtnLine">
                <button class="btn" type="submit">申请订购</button>
              </div>
            </form>
          </div>

          <div class="backView" v-show="submited">
            <template v-if="success">
              <img
                class="icon"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/09/icon_success.png"
                width="60"
                height="60"
              />
              <h2 class="title">恭喜您，提交成功！</h2>
              <p>我们的销售人员会在1-3个工作日和您联系</p>
            </template>
            <template v-else>
              <img
                class="icon"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/09/icon_fail.png"
                width="60"
                height="60"
              />
              <h2 class="title">Sorry，提交失败~</h2>
            </template>
            <div class="btnLine">
              <button class="btn" type="button" @click="hidePopup">知道了</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ec",
  data() {
    return {
      formData: {
        name: '',
        mobile: '',
        area: '',
        remark: '',
      },
      formDataCheck: {
        name: false,
        mobile: false,
        area: false,
      },
      popupShow: false,
      submited: false,
      success: false,
    }
  },
  methods: {
    showPopup() {
      this.popupShow = true;
      this.submited = false;
    },
    hidePopup() {
      this.popupShow = false;
      this.submited = false;
    },
    submitPopup(e) {
      e.preventDefault();

      const { formData, formDataCheck } = this;
      let verify;

      Object.keys(formDataCheck).forEach((key) => {
        formDataCheck[key] = !formData[key];

        if (formDataCheck[key]) {
          verify = true;
        }
      });

      if (verify) return;

      this.axios({
        url: 'https://api.partner.juzhen.cn/api/api/preorder/save',
        method: "post",
        data: {
          count: 1,
          prodId: 83,
          ...formData,
        }
      }).then((data) => {
        this.submited = true;
        this.success = data.code === 200;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  padding-bottom: 10px;
  background-color: #f0f0f0;
}
.popup {
  position: relative;
  z-index: 999;
}

.popupMasker, .popupContent {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.popupMasker {
  background-color: rgba(0, 0, 0, .7);
  pointer-events: auto;
}

.popupContent {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.bookView,
.backView {
  position: relative;
  margin: 36px;
  padding: 16px;
  background-color: #F7F8F9;
  border-radius: 16px;
}

.bookView {
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    transition: transform .3s;
    cursor: pointer;

    &:hover {
      transform: rotate(180deg);
    }
    
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      height: 2px;
      content: '';
      transform: rotate(45deg);
      background-color: #979797;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .tips {
    margin-bottom: 20px;
    padding: 0 36px 0 16px;
    font-size: 14px;
    color: #949B9C;
    line-height: 16px;

    .title {
      display: block;
      margin-bottom: 8px;
      font-size: 16px;
      color: #323334;
      line-height: 36px;
    }
  }
}

.backView {
  padding: 24px 16px;
  font-size: 14px;
  color: #949B9C;
  text-align: center;
  line-height: 16px;

  .title {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #323334;
  }

  .btnLine {
    margin: 40px auto 0;
    width: 50%;

    .btn {
      display: block;
      width: 100%;
      height: 32px;
      padding: 0;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 32px;
      border-radius: 28px;
      background: #e83e0b;
      border: none;
      outline: none;
    }
  }
}

.formList {
  margin: 12px 0;
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 8px;

  .item {
    display: flex;
    flex-wrap: wrap;
    line-height: 40px;
    border-bottom: 1px solid #D8D8D8;

    label {
      width: 80px;
      font-size: 14px;
      color: #323334;
      font-weight: bold;
    }

    .error {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: #f00;
    }
  }

  .itemBlock {
    display: block;
    border-bottom: none;
  }

  .input {
    flex: 1;
    padding: 4px 0;

    input {
      display: block;
      padding: 4px 0;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: none;
      outline: none;
    }
  }

  textarea {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    border: none;
    outline: none;
    resize: none;
  }
}

.formBtnLine {
  margin: 16px;

  .btn {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 40px;
    border-radius: 28px;
    background: #e83e0b;
    border: none;
    outline: none;
  }
}

.layout01 {
  margin: 0 auto;
  max-width: 1920px;
}

.btnBook {
  display: inline-block;
  vertical-align: top;
  padding: 0 52px;
  line-height: 60px;
  font-size: 34px;
  color: #fff;
  border-radius: 60px;
  background-color: #ff3000;
  cursor: pointer;
}

.banner {
  position: relative;
  height: 1218px;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(180deg, #dbe6e4 0, #b3c0bd 1080px, #f0f0f0 1080px);

  .preview {
    position: absolute;
    top: 138px;
    left: 50%;
    margin-left: -770px;
  }

  .summary {
    position: absolute;
    top: 405px;
    left: 50%;
    margin-left: 245px;
    text-align: center;
    z-index: 1;

    img {
      display: block;
      margin: 0 auto;
    }

    .tags {
      margin: 16px 0 30px;
      padding: 0 30px;
      font-size: 20px;
      line-height: 40px;
      color: #ff3000;
      border: 2px solid #ff3000;
      border-radius: 40px;

      span {
        & + span {
          &::before {
            margin: 0 16px;
            content: '|';
            font-size: 18px;
          }
        }
      }
    }

    .btnBook {
      margin-top: 80px;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .banner {
    height: 1058px;
    background-image: linear-gradient(180deg, #dbe6e4 0, #b3c0bd 920px, #f0f0f0 920px);

    .preview {
      left: 20px;
      margin-left: 0;
      width: auto;
      height: 920px;
    }

    .summary {
      top: 305px;
      left: auto;
      right: 50px;
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 1360px) {
  .banner {
    height: 958px;
    background-image: linear-gradient(180deg, #dbe6e4 0, #b3c0bd 820px, #f0f0f0 820px);

    .preview {
      left: 20px;
      margin-left: 0;
      width: auto;
      height: 820px;
    }

    .summary {
      top: 305px;
      left: auto;
      right: 50px;
      margin-left: 0;
    }
  }
}

.box {
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  overflow: hidden;

  h3 {
    margin-bottom: 2px;
    font-size: 74px;
    line-height: 84px;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}

.pass {
  position: relative;
  margin: 100px auto 0;
  padding: 0 85px;
  max-width: 1660px;

  img {
    margin: 0;
  }

  h3, p {
    position: absolute;
    right: 16px;
    top: 50%;
    width: 80%;
    max-width: 1024px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
  }
  
  h3 {
    margin: -20px 0 0;
    padding: 28px 0 0 32px;
    min-height: 240px;
    font-size: 60px;
    line-height: 70px;
    background-color: #f0f0f0;
  }

  p {
    margin-top: 80px;
    padding: 0 0 0 32px;
    z-index: 2;
  }
}

.space {
  height: 1110px;
  padding-top: 260px;
  text-align: center;
  background-image: linear-gradient(180deg, #f0f0f0 0, #bbcac6 900px, #f0f0f0 900px);

  img {
    margin-top: 84px;
  }
}

.cases {
  margin-top: 210px;

  h3 {
    margin-bottom: 5px;
    font-size: 64px;
    line-height: 74px;
  }

  .list {
    display: flex;
    margin-top: 72px;
    justify-content: center;

    li {
      flex: 1;
      max-width: 720px;
      padding: 0 80px;
      font-size: 28px;

      h4 {
        margin: 40px 0 0;
        font-size: 45px;
        font-family: 'PingFang SC';
      }
    }
  }
}

.shelf {
  margin-top: 150px;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1030px;
  background-image: linear-gradient(180deg, #dce7e6 0, #dce7e6 810px, #f0f0f0 810px);

  .tags {
    margin: 32px 0 56px;

    span {
      position: relative;
      display: inline-block;
      margin: 0 10px;
      vertical-align: top;
      padding: 0 26px;
      font-size: 28px;
      line-height: 58px;
      color: #ff3000;

      &::after {
        position: absolute;
        top: -50%;
        left: -50%;
        content: '';
        width: 200%;
        height: 200%;
        border: 3px solid #ff3000;
        border-radius: 6px;
        transform: scale(.5);
      }
    }
  }

  .view {
    margin: 0 0 0 -240px;
    position: relative;

    .detail {
      position: absolute;
      top: -168px;
      left: 100%;
      margin-left: -6px;
    }
  }
}

.safe {
  display: flex;
  margin: 140px auto 60px;
  padding-bottom: 132px;
  max-width: 1920px;
  flex-direction: row-reverse;

  .summary {
    display: flex;
    padding: 0 25px 60px;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: left;
    white-space: nowrap;
  }

  .imgWrap {
    display: block;
    height: 490px;
    overflow: visible;
    background-color: #222226;

    img {
      position: relative;
      top: 114px;
    }
  }
}

.delivery {
  display: flex;
  margin: 0 auto;
  max-width: 1920px;
  justify-content: flex-end;

  .wrap {
    position: relative;
    padding-left: 150px;
  }

  .summary {
    position: absolute;
    top: 190px;
    left: 0;
    padding: 24px 68px;
    min-height: 256px;
    background-color: #f0f0f0;
    z-index: 1;
  }
}

.scenes {
  display: flex;
  margin-top: 140px;
  height: 1095px;
  padding-top: 156px;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-image: linear-gradient(180deg, #e83e0b 0, #e83e0b 910px, #f0f0f0 910px);

  h3 {
    color: #fff;
  }

  .tags {
    margin: 36px 0 96px;

    span {
      position: relative;
      display: inline-block;
      margin: 0 10px;
      vertical-align: top;
      padding: 0 26px;
      font-size: 28px;
      line-height: 58px;
      color: #fff;

      &::after {
        position: absolute;
        top: -50%;
        left: -50%;
        content: '';
        width: 200%;
        height: 200%;
        border: 3px solid #fff;
        border-radius: 6px;
        transform: scale(.5);
      }
    }
  }

  .view {
    margin: 0 0 0 -50px;
  }

  // .view {
  //   position: relative;

  //   .car {
  //     position: relative;
  //     z-index: 5;
  //   }

  //   .courier {
  //     position: absolute;
  //     top: 76px;
  //     left: -38px;
  //   }
  // }
}

.bottom {
  margin: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .slogan {
    margin: 28px 0 50px;
  }
}

@media only screen and (max-width: 500px) {
  .body {
    background-color: #fff;
  }

  .btnBook {
    padding: 0 26px;
    line-height: 32px;
    font-size: 17px;
    border-radius: 30px;
  }

  .banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 708px;
    padding-top: 140px;
    overflow: visible;
    background-image: linear-gradient(180deg, #dfebe8 0, #c3d0cc 640px, #ffffff 640px);

    .preview {
      position: static;
      margin: 0;
      width: 100%;
      height: auto;
    }

    .summary {
      position: static;
      margin: 0;
      text-align: center;
      z-index: 1;

      img.brand {
        width: 232px;
        height: auto;
      }
      img.slogan {
        width: 204px;
        height: auto;
      }

      .tags {
        margin: 8px 0 16px;
        padding: 0 12px;
        font-size: 12px;
        line-height: 20px;
        border-width: 1px;
        border-radius: 20px;

        span {
          & + span {
            &::before {
              margin: 0 6px;
              font-size: 10px;
            }
          }
        }
      }

      .btnBook {
        margin-top: 24px;
      }
    }
  }

  .box {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    overflow: visible;

    h3 {
      margin-bottom: 8px;
      font-size: 38px;
      line-height: 42px;
    }
  }

  .pass {
    position: relative;
    padding: 52px 20px 36px;
    
    h3 {
      position: static;
      margin: 0 0 12px;
      padding: 0;
      width: 100%;
      min-height: auto;
      font-size: 25px;
      line-height: 38px;
      background-color: transparent;

      strong {
        display: block;
        font-size: 30px;
      }
    }

    p {
      position: static;
      margin: 12px 0 0;
      padding: 0;
      width: 100%;
    }
  }

  .space {
    height: 640px;
    padding-top: 100px;
    text-align: center;
    background-image: linear-gradient(180deg, #e0ebe8 0, #e0ebe8 40%, #bcc9c6 62%, #e0e6e5 63.5%, #e6ebe9 100%);

    img {
      margin-top: 76px;
    }
  }

  .cases {
    padding: 92px 0 14px;

    h3 {
      margin-bottom: 6px;
      font-size: 32px;
      line-height: 40px;
    }

    .list {
      display: block;
      margin: 18px auto 0;
      max-width: 224px;
      justify-content: center;

      li {
        margin-bottom: 28px;
        padding: 0;
        font-size: 14px;

        h4 {
          margin: 10px 0 0;
          font-size: 23px;
          line-height: 27px;
          font-family: 'PingFang SC';
        }
      }
    }
  }

  .shelf {
    padding-top: 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 640px;
    overflow: hidden;
    background-image: linear-gradient(180deg, #dce7e6 0, #dce7e6 520px, #ffffff 520px);

    .tags {
      margin: 0;
      padding: 14px 0 26px;

      span {
        display: block;
        margin: 16px auto;
        padding: 0 13px;
        font-size: 14px;
        line-height: 28px;

        &::after {
          top: -150%;
          left: -150%;
          width: 400%;
          height: 400%;
          border-radius: 12px;
          transform: scale(.25);
        }
      }
    }

    .view {
      margin: 0;
      position: relative;

      .detail {
        position: absolute;
        top: -118px;
        left: 50%;
        margin-left: 104px;
        width: 120px;
      }
    }
  }

  .safe {
    display: block;
    margin-bottom: 132px;
    color: #fff;
    padding-bottom: 0;
    background-color: #222226;

    .summary {
      padding: 120px 25px 40px;
      align-items: center;
      text-align: center;

      h3 {
        margin-bottom: 12px;
        color: #fff;

        strong {
          display: block;
        }
      }
    }

    .imgWrap {
      height: 190px;

      img {
        top: 0;
      }
    }
  }

  .delivery {
    display: block;

    .wrap {
      padding-left: 0;
    }

    .summary {
      position: absolute;
      top: 126px;
      left: 20px;
      right: 20px;
      padding: 24px 0;
      min-height: auto;
      background-color: #f0f0f0;
      z-index: 1;
    }
  }

  .scenes {
    margin-bottom: 52px;
    display: flex;
    height: auto;
    padding-top: 104px;
    background: #e83e0b;
    // background-image: linear-gradient(180deg, #e83e0b 0, #e83e0b 910px, #f0f0f0 910px);

    .tags {
      margin: 0;
      padding: 0;

      span {
        display: block;
        margin: 16px auto 0;
        padding: 0 13px;
        font-size: 14px;
        line-height: 28px;

        &::after {
          top: -150%;
          left: -150%;
          width: 400%;
          height: 400%;
          border-radius: 12px;
          transform: scale(.25);
        }
      }
    }

    .view {
      position: relative;
      top: 52px;
      margin: 0;
    }
  }

  .bottom {
    margin: 0;
    padding: 90px 0;

    img.brand {
      width: 232px;
      height: auto;
    }

    img.slogan {
      margin: 14px 0 40px;
      width: 204px;
      height: auto;
    }
  }
}
</style>